<template>
  <base-modal-view :title="editingDocument ? 'Edit Document' : 'Add Document'" @cancel="cancelClicked">
    <form class="flex-column" @submit.prevent="submitClicked">
      <p class="no-margin margin-top label" v-if="!editingDocument">Dealership</p>
      <select class="margin-top-025 dropdown-field" required v-model="document.dealership" v-if="!editingDocument">
        <option value="">-- Select a Dealership --</option>
        <option v-for="dealership in dealerships" v-bind:key="dealership.url" :value="dealership.url">
          {{ dealership.first_name }}
        </option>
      </select>
      <p class="no-margin margin-top label">Document Name</p>
      <input required v-model="document.description" type="text" class="margin-top-025 text-field" placeholder="Name" />
      <div v-if="!editingDocument" class="flex-column">
        <div class="flex-row margin-top label">
          <p>Document (Max file size, 3MB)</p>
          <p :class="{ error: getFileSize() > 3 }">({{ getFileSize() }}/3MB Used)</p>
        </div>
        <div class="flex-row margin-top-05">
          <input
            style="width: 80%"
            accept="image/jpeg"
            type="file"
            class="vertical-spacing-half input-field"
            id="file"
            ref="document"
            name="document"
            v-on:change="handleFileUpload($event)"
          />
          <button
            type="button"
            name="document"
            class="flex-end vertical-spacing-half no-button-style"
            title="Clear"
            v-on:click="handleFileClear"
          >
            X
          </button>
        </div>
      </div>
      <primary-button class="margin-top-1-05" title="Submit" />
    </form>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";

import { mapGetters } from "vuex";

export default {
  name: "NewDocumentModal",
  components: { PrimaryButton, BaseModalView },
  props: ["editingDocument"],
  data: function () {
    return {
      document: {
        description: "",
        dealership: "",
        document: null
      }
    };
  },
  methods: {
    submitClicked: function () {
      if (!this.document.document) {
        alert("Please select a document to upload.");
        return;
      }
      if (this.editingDocument) {
        this.$emit("editDocument", this.document);
      } else {
        this.$emit("createDocument", this.document);
      }
    },
    cancelClicked: function () {
      this.$emit("cancel");
    },
    handleFileUpload: function (event) {
      this.document.document = event.target.files[0];
    },
    handleFileClear: function () {
      this.$refs["document"].value = null;
      this.document.document = null;
    },
    getFileSize: function () {
      if (!this.document.document) {
        return 0;
      }
      return (this.document.document.size / (1024 * 1024)).toFixed(1);
    }
  },
  computed: {
    ...mapGetters({
      dealerships: "dealershipStore/dealerships"
    })
  },
  mounted: function () {
    if (this.editingDocument) {
      this.document = this.editingDocument;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";
</style>

<template>
  <base-modal-view :title="editingDealerPack ? 'Edit Dealer Pack' : 'Add Dealer Pack'" @cancel="cancelClicked">
    <form class="flex-column" @submit.prevent="submitClicked">
      <p class="no-margin margin-top label" v-if="!editingDealerPack">Dealership</p>
      <select class="margin-top-025 dropdown-field" required v-model="dealerPack.dealership" v-if="!editingDealerPack">
        <option value="">-- Select a Dealership --</option>
        <option v-for="dealership in dealerships" v-bind:key="dealership.url" :value="dealership.url">
          {{ dealership.first_name }}
        </option>
      </select>
      <p class="no-margin margin-top label">Dealer Pack Name</p>
      <input v-model="dealerPack.description" type="text" class="margin-top-025 text-field" placeholder="Name" />
      <table v-if="editingDealerPack" class="results-table margin-top">
        <tr class="header">
          <th>Document Name</th>
          <th>Document</th>
          <th>Actions</th>
        </tr>
        <tr class="data-row" v-for="document in editingDealerPack.documents" v-bind:key="document.id">
          <td>{{ document.description }}</td>
          <td><tiny-button title="Document" type="button" v-on:click="goToDocument(document)" /></td>
          <td><tiny-button title="Remove" type="button" v-on:click="removeDocument(document)" /></td>
        </tr>
      </table>
      <primary-button class="margin-top-1-05" title="Submit" />
    </form>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import TinyButton from "../../Buttons/v2/TinyButton.vue";
import BaseModalView from "./BaseModalView.vue";

import { mapGetters } from "vuex";

export default {
  name: "NewDealerPackModal",
  components: { PrimaryButton, TinyButton, BaseModalView },
  props: ["editingDealerPack"],
  data: function () {
    return {
      dealerPack: {
        description: "",
        dealership: ""
      }
    };
  },
  methods: {
    submitClicked: function () {
      if (this.editingDealerPack) {
        this.$emit("editPack", this.dealerPack);
      } else {
        this.$emit("createDealerPack", this.dealerPack);
      }
    },
    cancelClicked: function () {
      this.$emit("cancel");
    },
    removeDocument: function (document) {
      this.$emit("removeDocument", document);
    },
    goToDocument(document) {
      let route = this.$router.resolve({
        name: "InternalDocument",
        params: { id: document.id }
      });
      window.open(route.href, "_blank");
    }
  },
  computed: {
    ...mapGetters({
      dealerships: "dealershipStore/dealerships"
    })
  },
  mounted: function () {
    if (this.editingDealerPack) {
      this.dealerPack = this.editingDealerPack;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";
</style>

<template>
  <div>
    <div id="internal-documents-page">
      <h1 class="h1-title">Internal Documents</h1>
      <div class="flex-row margin-top-2">
        <secondary-button
          title="Add new document"
          icon="/assets/img/icon-plus.svg"
          class="min-width-192 margin-right-05"
          :disabled="selectedDocuments.length > 0 || selectedPacks.length > 0"
          v-on:click="
            selectedDocuments.length > 0 || selectedPacks.length > 0 ? () => {} : (() => (addingDocument = true))()
          "
        />
        <secondary-button
          title="Add new dealer pack"
          icon="/assets/img/icon-plus.svg"
          class="min-width-192 margin-right-05"
          :disabled="selectedDocuments.length > 0 || selectedPacks.length > 0"
          v-on:click="
            selectedDocuments.length > 0 || selectedPacks.length > 0 ? () => {} : (() => (addingDealerPack = true))()
          "
        />
        <search-bar
          class="margin-right flex-grow"
          availableFilterMode="departments"
          placeholder="Search for pack or document name"
          :emitActions="true"
          @applyFilter="applyFilter"
        >
          <secondary-button
            icon="/assets/img/icon-pencil.svg"
            title="Edit"
            class="min-width-120 margin-left-05"
            :disabled="!(selectedDocuments.length + selectedPacks.length == 1)"
            v-on:click="editSelectedDocument()"
          />
          <secondary-button
            icon="/assets/img/icon-trash.svg"
            title="Delete"
            class="min-width-120 margin-left-05"
            :disabled="!(selectedDocuments.length + selectedPacks.length > 0)"
            v-on:click="callDeleteDocuments()"
          />
          <secondary-button
            icon="/assets/img/icon-plus.svg"
            title="Add Documents to Packs"
            class="min-width-120 margin-left-05"
            :disabled="!(selectedDocuments.length > 0 && selectedPacks.length > 0)"
            v-on:click="callAddDocumentsToPack()"
          />
        </search-bar>
      </div>
      <filter-bar :customFilters="filters" @removeFilter="removeFilter" />

      <div class="results-table-container margin-top">
        <table class="results-table">
          <tr class="header">
            <th>
              <input v-model="selectAllPacksCheckbox" type="checkbox" name="" id="" />
            </th>
            <th>Dealer Pack Name</th>
            <th>Dealership</th>
            <th>Attachments</th>
            <th>Document</th>
          </tr>
          <tr
            v-for="pack in filteredDealerPacks().sort((a, b) => a.title - b.title)"
            v-bind:key="pack.id"
            class="data-row"
          >
            <td>
              <input type="checkbox" name="" id="" v-model="selectedPacks" :value="pack" />
            </td>
            <td>{{ pack.description }}</td>
            <td class="dealership">{{ pack.dealership.name }}</td>
            <td>
              <div class="flex-row">
                <p v-for="doc in pack.documents" v-bind:key="doc.url">
                  {{ doc.description }} <span v-if="pack.documents.indexOf(doc) != pack.documents.length - 1">, </span>
                </p>
              </div>
            </td>
            <td>
              <tiny-button title="Document" v-on:click="goToPack(pack)" />
            </td>
          </tr>
        </table>
        <table class="results-table">
          <tr class="header">
            <th>
              <input v-model="selectAllDocsCheckbox" type="checkbox" name="" id="" />
            </th>
            <th>Document Name</th>
            <th>Dealership</th>
            <th>Document</th>
          </tr>
          <tr
            v-for="document in filteredDocuments().sort((a, b) => a.title - b.title)"
            v-bind:key="document.id"
            class="data-row"
          >
            <td>
              <input type="checkbox" name="" id="" v-model="selectedDocuments" :value="document" />
            </td>
            <td>{{ document.description }}</td>
            <td class="dealership">{{ document.dealership.name }}</td>
            <td>
              <tiny-button title="Document" v-on:click="goToDocument(document)" />
            </td>
          </tr>
        </table>
      </div>
    </div>
    <new-document-modal
      v-if="addingDocument || selectedDocument"
      :editingDocument="selectedDocument"
      @cancel="
        addingDocument = false;
        selectedDocument = null;
      "
      @createDocument="callCreateDocument"
      @editDocument="callUpdateDocument"
    />
    <new-dealer-pack-modal
      v-if="addingDealerPack || selectedPack"
      :editingDealerPack="selectedPack"
      @cancel="
        addingDealerPack = false;
        selectedPack = null;
      "
      @createDealerPack="callCreateDealerPack"
      @editPack="callUpdatePack"
      @removeDocument="callRemoveDocumentsFromPack"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import {
  createInternalDocument,
  fetchInternalDocuments,
  updateInternalDocument,
  deleteInternalDocument,
  createDealerPack,
  fetchDealerPacks,
  addDocumentsToPack,
  deleteDealerPack,
  updateDealerPack,
  removeDocumentsFromPack
} from "../../../api/v2/InternalDocuments.js";

import SearchBar from "../../../components/Views/v2/SearchBar.vue";
import FilterBar from "../../../components/Views/v2/FilterBar.vue";
import TinyButton from "../../../components/Buttons/v2/TinyButton.vue";
import SecondaryButton from "../../../components/Buttons/v2/SecondaryButton.vue";
import NewDocumentModal from "../../../components/Modals/v2/NewDocumentModal.vue";
import NewDealerPackModal from "../../../components/Modals/v2/NewDealerPackModal.vue";
import _ from "lodash";

export default {
  name: "InternalDocuments",
  components: {
    SecondaryButton,
    TinyButton,
    SearchBar,
    FilterBar,
    NewDocumentModal,
    NewDealerPackModal
  },
  data: function () {
    return {
      documents: [],
      dealerPacks: [],
      selectAllDocsCheckbox: false,
      selectAllPacksCheckbox: false,
      addingDocument: false,
      addingDealerPack: false,
      selectedPack: null,
      selectedPacks: [],
      selectedDocument: null,
      selectedDocuments: [],
      filters: {}
    };
  },
  methods: {
    filteredDocuments: function () {
      var toReturn = this.documents;
      if ("quick_search" in this.filters) {
        var quickSearch = this.filters["quick_search"];
        if (quickSearch != "") {
          toReturn = toReturn.filter(document => {
            if (!document.description.toLowerCase().includes(quickSearch.toLowerCase())) {
              return false;
            }

            return true;
          });
        }
      }
      if ("dealership" in this.filters) {
        toReturn = toReturn.filter(document => document.dealership.id == this.filters["dealership"]);
      }
      if ("dealership_not" in this.filters) {
        toReturn = toReturn.filter(document => document.dealership.id != this.filters["dealership_not"]);
      }
      return toReturn;
    },
    filteredDealerPacks: function () {
      var toReturn = this.dealerPacks;
      if ("quick_search" in this.filters) {
        var quickSearch = this.filters["quick_search"];
        if (quickSearch != "") {
          toReturn = toReturn.filter(pack => {
            var quickToReturn = false;
            if (pack.description.toLowerCase().includes(quickSearch.toLowerCase())) {
              quickToReturn = true;
            }
            pack.documents.forEach(doc => {
              if (doc.description.toLowerCase().includes(quickSearch.toLowerCase())) {
                quickToReturn = true;
              }
            });

            return quickToReturn;
          });
        }
      }
      if ("dealership" in this.filters) {
        toReturn = toReturn.filter(pack => pack.dealership.id == this.filters["dealership"]);
      }
      if ("dealership_not" in this.filters) {
        toReturn = toReturn.filter(pack => pack.dealership.id != this.filters["dealership_not"]);
      }
      return toReturn;
    },
    callFetchDocuments: function () {
      fetchInternalDocuments()
        .then(response => {
          this.documents = response;
        })
        .catch(error => {
          this.addError(error);
        });
    },
    callCreateDocument: function (document) {
      createInternalDocument(document)
        .then(response => {
          this.documents.push(response);
          this.addingDocument = false;
        })
        .catch(error => {
          this.addError(error);
        });
      this.addingDocument = false;
    },
    callUpdateDocument: function (document) {
      updateInternalDocument(document)
        .then(response => {
          this.documents = this.documents.map(doc => {
            if (doc.id == response.id) {
              return response;
            }
            return doc;
          });
          this.selectedDocument = null;
          this.selectedDocuments = [];
        })
        .catch(error => {
          this.addError(error);
        });
    },
    callDeleteDocuments: function () {
      var shouldDelete = confirm(
        "Are you sure you want to delete " +
          this.selectedDocuments.length +
          " selected document(s) and " +
          this.selectedPacks.length +
          " selected Dealer Pack(s)?"
      );
      if (shouldDelete) {
        this.selectedDocuments.forEach(document => {
          deleteInternalDocument(document)
            .then(() => {
              this.documents = this.documents.filter(doc => doc.id != document.id);
              this.selectedDocuments = this.selectedDocuments.filter(doc => doc.id != document.id);
            })
            .catch(error => {
              this.addError(error);
            });
        });

        this.selectedPacks.forEach(pack => {
          deleteDealerPack(pack)
            .then(() => {
              this.dealerPacks = this.dealerPacks.filter(p => p.id != pack.id);
              this.selectedPacks = this.selectedPacks.filter(p => p.id != pack.id);
            })
            .catch(error => {
              this.addError(error);
            });
        });
      }
    },
    callCreateDealerPack: function (dealerPack) {
      createDealerPack(dealerPack)
        .then(response => {
          this.dealerPacks.push(response);
          this.addingDealerPack = false;
        })
        .catch(error => {
          this.addError(error);
        });
      this.addingDealerPack = false;
    },
    callFetchDealerPacks: function () {
      fetchDealerPacks()
        .then(response => {
          this.dealerPacks = response;
        })
        .catch(error => {
          this.addError(error);
        });
    },
    callAddDocumentsToPack: function () {
      this.selectedPacks.forEach(pack => {
        addDocumentsToPack(this.selectedDocuments, pack)
          .then(response => {
            pack.documents = response.documents;
          })
          .catch(error => {
            this.addError(error);
          });
      });
    },
    callUpdatePack: function (pack) {
      updateDealerPack(pack)
        .then(response => {
          this.dealerPacks = this.dealerPacks.map(p => {
            if (p.id == response.id) {
              return response;
            }
            return p;
          });
          this.selectedPack = null;
          this.selectedPacks = [];
        })
        .catch(error => {
          this.addError(error);
        });
    },
    callRemoveDocumentsFromPack: function (document) {
      removeDocumentsFromPack([document], this.selectedPack)
        .then(() => {
          this.selectedPack.documents = this.selectedPack.documents.filter(d => d.id !== document.id);
          this.dealerPacks.forEach(pack => {
            if (pack.id === this.selectedPack.id) {
              pack.documents = this.selectedPack.documents;
            }
          });
        })
        .catch(error => {
          this.addError(error);
        });
    },
    editSelectedDocument: function () {
      if (this.selectedDocuments.length > 0) {
        this.selectedDocument = _.cloneDeep(this.selectedDocuments[0]);
      } else if (this.selectedPacks.length > 0) {
        this.selectedPack = _.cloneDeep(this.selectedPacks[0]);
      }
    },
    applyFilter: function (filter) {
      var newFilters = _.cloneDeep(this.filters);
      newFilters[filter.filter.key] = filter.filter.value;
      this.filters = newFilters;
    },
    removeFilter: function (filter) {
      delete this.filters[filter.key];
    },
    goToDocument(document) {
      let route = this.$router.resolve({
        name: "InternalDocument",
        params: { id: document.id }
      });
      window.open(route.href, "_blank");
    },
    goToPack(pack) {
      let route = this.$router.resolve({
        name: "DealerPack",
        params: { id: pack.id }
      });
      window.open(route.href, "_blank");
    },
    ...mapActions({
      getDealerships: "dealershipStore/fetchDealerships",
      addError: "errorStore/addError"
    })
  },
  watch: {
    selectAllDocsCheckbox: function (newVal) {
      if (newVal) {
        this.selectedDocuments = this.documents;
      } else {
        this.selectedDocuments = [];
      }
    },
    selectAllPacksCheckbox: function (newVal) {
      if (newVal) {
        this.selectedPacks = this.dealerPacks;
      } else {
        this.selectedPacks = [];
      }
    }
  },
  computed: {
    ...mapGetters({
      dealerships: "dealershipStore/dealerships",
      dealershipWithId: "dealershipStore/dealershipWithId"
    })
  },
  mounted: function () {
    this.getDealerships();
    this.callFetchDocuments();
    this.callFetchDealerPacks();
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#internal-documents-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
}
</style>
